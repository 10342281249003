<template>
  <v-card
    style="border: 1px solid #eee; box-shadow: none;"
  >
    <div class="d-flex justify-space-between align-center pa-2 grey lighten-4">
      <div>
        <span class="font-weight-medium">Credit/Debit Card</span>
      </div>
      <div>
        <img style="width:auto; height: 20px;" src="@/assets/credit-card-icons.png" />
      </div>
    </div>
    <div class="pa-4">
      <div class="card-label">Card number</div>
      <div class="input-wrapper">
        <div id="card-number"></div>
      </div>
      <div class="d-flex">
        <div class="pr-2 flex-grow-1">
          <div class="card-label">Expiry</div>
          <div class="input-wrapper">
            <div id="card-expiry"></div>
          </div>
        </div>
        <div class="pl-2 flex-grow-1">
          <div class="card-label">CVC</div>
          <div class="input-wrapper">
            <div id="card-cvc"></div>
          </div>
        </div>
      </div>
      <div class="card-label">Name on card</div>
      <div class="input-wrapper">
        <input v-model="cardName" placeholder="Enter name" />
      </div>
      <div v-if="errorMsg" id="card-error">{{ errorMsg }}</div>
      <v-btn
        depressed
        class="mt-4"
        color="primary"
        height="48"
        @click="createToken"
        block
        :loading="localLoading"
      >{{btnLabel}}</v-btn>
      <div v-if="localLoading" class="mt-2 pa-2 processing-card">
        <div class="body-2 text-center">Card is being processed, don't close or reload page.</div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CreditCardForm',
  props: {
    btnLabel: {
      default: 'Purchase'
    },
    loading: {
      default: false,
    },
  },
  data: () => ({
    token: null,
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
    cardName: '',
    errorMsg: '',
  }),
  mounted () {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
    localLoading: {
      get () {
        return this.loading
      },
      set (val) {
        this.$emit('update:loading', val)
      }
    }
  },
  methods: {
    async createToken () {
      this.errorMsg = ''
      this.localLoading = true
      if (this.cardName.replace(' ', '').length === 0) {
        this.errorMsg = 'Name is required'
        this.localLoading = false
        return
      }
      const { token, error } = await this.$stripe.createToken(this.cardNumber, { name: this.cardName });
      if (error) {
        // handle error here
        this.errorMsg = error.message;
        this.localLoading = false
        return;
      }

      this.$emit('submit', token)
    },
  },
}
</script>

<style scoped>
#card-error {
  color: red;
}

.card-label {
  color: #444444;
  margin-bottom: 4px;
}

.input-wrapper {
  border-radius: 4px;
  border: 1px solid #bbb;
  margin-bottom: 8px;
  padding: 8px;
}

.input-wrapper input {
  outline: none;
  width: 100%;
}

.input-wrapper input::placeholder {
  color: #aab7c4;
}

.processing-card {
  background-color: #ccdef8;
  border-radius: 4px;
}
</style>
