<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="user_access.isRenewing"
        key="sub"
      >
        <v-divider class="mb-4" />
        <p>
          Your account is currently active and will be renewed on
          {{ expDate }} for ${{ price }}.
        </p>
        <v-expansion-panels
          flat
          class="mb-4"
          accordion
        >
          <v-expansion-panel style="border: 1px solid #ccc">
            <v-expansion-panel-header class="font-weight-medium"
              >Change Card</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>
                If you would like to change the card you use to pay for your
                account, enter the details below.
              </p>
              <credit-card-form
                btn-label="Update Card"
                @submit="updateSubscription"
                :loading.sync="subscriptionLoading"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel style="border: 1px solid #ccc; border-top: none">
            <v-expansion-panel-header class="font-weight-medium"
              >Cancel Subscription</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p class="w-label">Would you like to cancel your subscription?</p>
              <p>
                You will still have full access up until the renewal date stated
                above. After this date you will still be able to access your
                account but won't be able to change and download your Will.
              </p>
              <p>
                Click below to confirm you would like to cancel your
                subscription
              </p>
              <p>
                <v-btn
                  depressed
                  height="48"
                  color="primary"
                  :loading="cancelProcessing"
                  @click="cancelSubscription"
                  >Cancel Subscription</v-btn
                >
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div
        v-else
        key="nosub"
      >
        <v-btn
          color="primary"
          height="48"
          depressed
          @click="$router.push('/checkout')"
          >Renew Account</v-btn
        >
        <v-divider class="mb-4 mt-4" />
        <p>
          Your account is currently active but will expire on
          {{ expDate }}.
        </p>
        <p>
          You will still be able to log in to your account after this date but
          won't be able to update and download your Will.
        </p>
        <p>
          Renewing your account for another year costs ${{ price }} and allows
          you to make unlimited updates to your Will.
        </p>
        <p>
          If your account lapses, you will need to repurchase your Will at the
          full price if you wish to make changes in the future.
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import CreditCardForm from "../../../../components/checkout/CreditCardForm"

export default {
  name: "AccessActive",
  components: { CreditCardForm },
  props: ["price", "plan"],
  data: () => ({
    tab: 0,
    cancelProcessing: false,
    subscriptionLoading: false,
  }),
  computed: {
    ...mapGetters("account", ["user_access"]),
    ...mapGetters("app", ["pricing"]),
    expDate() {
      return `${this.user_access.value.expiryDate.day}/${this.user_access.value.expiryDate.month}/${this.user_access.value.expiryDate.year}`
    },
  },
  methods: {
    async updateSubscription(token) {
      this.$store
        .dispatch("account/update_subscription", token)
        .then((result) => {
          this.$store.commit("app/SET_USER_MENU_ALERT", result)
          this.subscriptionLoading = false
          this.$emit("close")
        })
    },
    async cancelSubscription() {
      this.cancelProcessing = true
      this.$store.dispatch("account/cancel_subscription").then((result) => {
        this.$store.commit("app/SET_USER_MENU_ALERT", result)
        this.cancelProcessing = false
        this.$emit("close")
      })
    },
  },
}
</script>
